import React from 'react'
import Layout from '../components/layout'
import {suggestionForm, textSuggestion, inputSuggestion, suggestionButton, formLabel} from '../components/layout.module.css'


const Suggest = () => {  
  return (
    <Layout pageTitle="Suggestion Box">

  <form action="https://submit-form.com/yOSAYcua" className={suggestionForm}>
  <label for="Name" className={formLabel}>Name</label>
  <input type="text" id="Name" name="Name" placeholder="Aglae Beaulieu" required="" className={inputSuggestion}/>
  <label for="Email" className={formLabel}>Email</label>
  <input type="email" id="Email" name="Email" placeholder="aglae@webaddress.com" required="" className={inputSuggestion}/>
  <label for="Suggestion" className={formLabel}>Suggestion</label>
  <input
    id="Suggestion"
    name="Suggestion"
    placeholder="Suggestion"
    required=""
    className={textSuggestion}></input>
  <button type="submit" className={suggestionButton}>Submit</button>
</form>

    </Layout>
  )
}

export default Suggest